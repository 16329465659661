<template>
    <div class="container">
        <qy-top-nav title="经销商信息" />
        <div class="dealer-card">
            <ul>
                <li v-for="item in dataList" @click="detailDealer(item.id)">
                    <a href="#" class="dealer-name">{{item.dealerName}} </a>
                    <a href="javascript:;" class="close" @click.stop="payDealer(item.id)" v-if="item.payStatus == 0">
                        <van-icon name="balance-pay" /></a>&nbsp;
                    <a href="javascript:;" class="edit" @click.stop="editDealer(item.id)" v-if="item.status == 0">
                        <van-icon name="edit" /></a>&nbsp;
                    <a href="javascript:;" class="close" @click.stop="deleteDealer(item.id)" v-if="item.status == 0 && item.payStatus == 0">
                        <van-icon name="delete-o" /></a>
                    <div>
                        <van-tag round :type="statusObj[item.status].type">{{statusObj[item.status].label}}</van-tag>
                    </div>
                </li>
            </ul>
            <div style="color: #333;padding:5px;">经销商资料添加后，在商城店铺提交资料，审核通过之后成为店铺经销商。</div>
            <van-button type="info" block round @click="addDealer" style="margin-top:10%;"><i class="fa fa-plus"></i>完善资料</van-button>
        </div>
    </div>
</template>
<script>
import request from "@/utils/request";
import { Toast } from 'vant';
export default {
    name: "DealerInfo",
    data() {
        return {
            dataList: [],
            isInWxMini: false,
            statusObj: {
                0: { label: "待审核", type: "default" },
                1: { label: "审核中", type: "warning" },
                2: { label: "已通过", type: "success" },
                9: { label: "已拒绝", type: "danger" },
            }
        }
    },
    created() {
        /*if(top&&top.location.href!=location.href){
            this.Toast.success(top.location.href)
            top.location.href=location.href;
        }*/

        let payUrl = this.storage.pop("payUrl");
        if (payUrl) {
            location.href = (decodeURIComponent(payUrl))
            return;
        }
        this.getList();
        this.isInWxMiniPro((rs) => {
            this.isInWxMini = rs;
        })
    },
    methods: {

        getList() {
            request.post('/api/dealer/list').then(res => {
                if (res.code == 0) {
                    this.dataList = res.data;
                    if (!res.data || res.data.length < 1) {
                        this.Dialog.confirm({
                            message: '经销商信息为空,是否马上添加?',
                        }).then(() => { this.addDealer() });
                    }
                } else {
                    return Toast.fail(res.msg);
                }
            });
        },
        addDealer() {
            this.$router.push("/addDealer");
        },
        editDealer(id) {
            this.$router.push({ path: "/editDealer", query: { id: id } })
        },
        payDealer(id) {
            if (this.isInWxMini) {
                this.openWxMiniPay({ orderId: id, type: 0, callback: 'dealerInfo' });
                return;
            }
            let redirectUrl = location.origin + "/dealerInfo";
            request.post("/api/dealer/payment", {
                dealerId: id,                
                redirectUrl: redirectUrl
            }).then(result => {
                if (result.success) {
                    let data = result.data;
					let payUrl = decodeURIComponent(data.payData);
					this.openWxH5Pay(payUrl);
                } else {
                    return Toast.fail(result.msg || "生成支付码失败");
                }
            });
            //this.$router.push({ path: "/deliverInfoPay", query: { dealerId: id } });
        },
        deleteDealer(id) {
            this.Dialog.confirm({
                message: '确定要删除经销商信息吗?'
            }).then(() => {
                request.post("/api/dealer/delete", { id: id }).then(rs => {
                    if (rs.code == 0) {
                        this.getList();
                    }
                });
            });
        },
        detailDealer(id) {
            this.$router.push({ path: "/detailDealer", query: { id: id } })
        }
    }
}
</script>
<style scoped>
.van-dialog {
    font-size: 30px;
}

div.container {
    margin: 0px;
    padding: 0px;
    font-size: 14px;
}

.dealer-card {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -50%);
    text-align: center;
    line-height: 30px;
    color: #333;

}

ul>li {
    border-radius: 3px;
    border: 1px #ddd solid;
    margin-bottom: 5px;
    padding: 25px 10px;
    height: auto;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

ul>li>a:hover {
    background-color: #feb74c;
}

.fa {
    font-size: 16px;
    font-weight: none;
    margin-right: 5px;
}

.van-button {
    background: none;
    border: 1px solid #c00;
    color: #c00;
    margin-top: 10px;
}
</style>